import { Meta, Title } from '@solidjs/meta';
import { Heading, Container, Section, Page, Breadcrumbs, BreadcrumbItem } from '@troon/ui';
import { For, Show } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { Navigate, useLocation } from '@solidjs/router';
import { getConfigValue } from '../../modules/config';
import { ImageOverlay } from '../../components/image-overlay';
import { Benefit } from '../../components/benefit';
import { useUser } from '../../providers/user';
import type { RouteDefinition } from '@solidjs/router';
import type { ComponentProps } from 'solid-js';

export default function AccessBenefits() {
	const user = useUser();
	const loc = useLocation();
	return (
		<Show when={user()?.activeTroonCardSubscription} fallback={<Navigate href={`/auth?redirect=${loc.pathname}`} />}>
			<Title>Troon Access Benefits | Troon Access | Troon</Title>
			<Meta
				name="description"
				content="The ultimate way to experience Troon golf. Exclusive access to savings at more than 150 participating Troon golf courses."
			/>

			<div class="relative mb-12 flex min-h-96 flex-col items-stretch bg-gradient-to-r from-neutral-950 to-brand-700 text-white sm:min-h-72 md:min-h-96">
				<Container class="z-20 -mb-20 mt-20 text-white">
					<Breadcrumbs appearance="current">
						<BreadcrumbItem href="/access">Troon Access</BreadcrumbItem>
						<BreadcrumbItem href="/access/benefits">Benefits</BreadcrumbItem>
					</Breadcrumbs>
				</Container>

				<div class="z-10 mx-auto my-16 flex h-full max-w-3xl grow flex-col items-center justify-center gap-4 px-4 pt-16 text-center text-white">
					<Heading as="h1">Troon Access Benefits</Heading>
					<p>Your Troon Access membership gets you access to incredible savings.</p>
				</div>
				<ImageOverlay class="relative top-1" />
			</div>

			<Page>
				<NoHydration>
					<Container>
						<Section>
							<div class="mb-12 grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-8 lg:grid-cols-3">
								<For each={benefits}>{(benefit) => <Benefit {...benefit} level="h3" />}</For>
							</div>
						</Section>
					</Container>
				</NoHydration>
			</Page>
		</Show>
	);
}

const benefits: Array<ComponentProps<typeof Benefit>> = [
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-avis.jpeg`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/avis.jpg`,
		title: 'Avis',
		content: 'Get 35% off rental cars.',
		url: 'https://www.avis.com/en/association/B357501',
		linkText: 'Visit Avis',
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-ship-sticks.png`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/ship-sticks.png`,
		title: 'Ship Sticks',
		content: 'Save $15 off your shipment.',
		url: 'https://www.shipsticks.com/troon',
		linkText: 'Visit Ship Sticks',
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-shot-scope.png`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/shot-scope.png`,
		title: 'Shot Scope',
		content: (
			<>
				Save $50 on your order with coupon code <code class="font-semibold">FAIRWAYFINDS</code>.
			</>
		),
		url: 'https://shotscope.com/us/',
		linkText: 'Visit Shot Scope',
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-leupold-golf.png`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/leupold-golf.png`,
		title: 'Leupold Golf',
		content: (
			<>
				Save 25% on your order with coupon code <code class="font-semibold">TROON25</code>.
			</>
		),
		url: 'https://leupoldgolf.com',
		linkText: 'Visit Leupold Golf',
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-bad-birdie.png`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/bad-birdie.png`,
		title: 'Bad Birdie',
		content: 'Save 25% on your order.',
		url: 'https://badbirdiegolf.com/pages/troon-access-members',
		linkText: 'Visit Bad Birdie',
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-sunday-golf.png`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/sunday-golf.jpeg`,
		title: 'Sunday Golf',
		content: (
			<>
				Spend $50 or more and use code <code class="font-semibold">SUNDAYSTARS</code> get a free Stars and Stripes towel
				($34.99 value).
			</>
		),
		url: 'https://sundaygolf.com/',
		linkText: 'Visit Sunday Golf',
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-zero-friction.jpeg`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/zero-friction.png`,
		title: 'Zero Friction',
		content: (
			<>
				Save 10% on your order with coupon code <code class="font-semibold">WELCOME10</code>.
			</>
		),
		url: 'https://zerofriction.com',
		linkText: 'Visit Zero Friction',
	},
	{
		background: `${getConfigValue('IMAGE_HOST')}/digital/logos/hero-full-swing.jpg`,
		image: `${getConfigValue('IMAGE_HOST')}/digital/logos/full-swing.png`,
		title: 'Full Swing',
		content: (
			<>
				Get 15% off the Full Swing Kit or Kit Studio with promo code <code class="font-semibold">TRNFS</code>.
			</>
		),
		url: 'https://www.fullswinggolf.com/kit-launch-monitor/',
		linkText: 'Visit Full Swing',
	},
];

export const route = { info: { hasHero: true } } satisfies RouteDefinition;

export { benefits };
